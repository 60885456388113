import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
    Button,
    Dropdown,
    Group,
    InformationType,
    TextInput,
    useTheme,
    Text,
    TextType,
    Grid,
    Heading,
    Level,
    Screen,
    ThemeProvider,
    themes,
    Logo,
    Icon,
} from '@defa/defa-component-library';
// eslint-disable-next-line import/extensions
import { isAndroid, isIOS } from 'src/utils/detect-device';
// eslint-disable-next-line import/extensions
import { CountryType, getCountryCodeValueList } from 'src/utils/country-list';
// eslint-disable-next-line import/extensions
import { updatePhoneNumberWithCountry, validateCombinedPhoneNumber } from 'src/utils/phone-number';
import type { CountryCode } from 'libphonenumber-js';
import { useQueryParam } from '../../utils/hooks';
import { AndroidDownload, IconContainer, IOSDownload } from './anonymous-invite-home.styles';
import i18n from '../../i18n';
import { AnonymousSetupResponseStatus } from '../../models';
import {
    useAcceptAnonymousInvitationHomeMutation,
    useValidateAnonymousInvitationHomeQuery,
} from './anonymous-invite-home.queries.generated';

export const AnonymousInviteHome: React.FunctionComponent = () => {
    const theme = useTheme();
    const query = useQueryParam();
    const token = query.get('token');

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [done, setDone] = useState<boolean>(false);
    const [error, setError] = useState<AnonymousSetupResponseStatus | undefined>();
    const [selectedCountryCode, setSelectedCountryCode] = useState<string | undefined>();

    const {
        data,
        loading,
        error: validateError,
        refetch,
    } = useValidateAnonymousInvitationHomeQuery({
        fetchPolicy: 'network-only',
        variables: { token: token ?? '' },
    });

    const { anonymousInvite, countries = [] } = data ?? {};
    const { status } = anonymousInvite || {};

    // Extract and process country codes.
    const countryCodes: CountryCode[] = countries
        .map((c) => c?.id)
        .filter((id): id is CountryCode => !!id);
    const countriesList: CountryType[] = getCountryCodeValueList(countryCodes);
    const country = countriesList.find((c) => c.countryCode === selectedCountryCode);

    const [acceptInvite, { loading: loadingSave }] = useAcceptAnonymousInvitationHomeMutation();

    const submit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();
            const { data: accept } = await acceptInvite({
                variables: {
                    phoneNumber: updatePhoneNumberWithCountry(
                        phoneNumber,
                        country?.callingCode ?? ''
                    ),
                    countryCode: country?.countryCode ?? '',
                    token: token ?? '',
                },
            });
            if (
                accept?.acceptAnonymousInviteHome?.status === AnonymousSetupResponseStatus.SUCCESS
            ) {
                setDone(true);
            } else {
                setError(AnonymousSetupResponseStatus.INVALID);
            }
        },
        [acceptInvite, phoneNumber, country?.callingCode, country?.countryCode, token]
    );

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (validateError) {
            setError(AnonymousSetupResponseStatus.INVALID);
        }
    }, [validateError]);

    const isPhoneNumberOk = validateCombinedPhoneNumber(country?.callingCode ?? '', phoneNumber);

    const hasError =
        status === AnonymousSetupResponseStatus.INVALID ||
        status === AnonymousSetupResponseStatus.NO_TOKEN ||
        error;
    const showForm = !done && !hasError && status === AnonymousSetupResponseStatus.SUCCESS;

    return (
        <ThemeProvider theme={themes[1]}>
            <Screen loading={loading}>
                <Group maxWidth="480px" form onSubmit={submit}>
                    <Logo variant="defa" size={64} />

                    {hasError && (
                        <Group>
                            <Heading level={Level.h1}>
                                {i18n.t('AnonymousInviteHome.ErrorHeader')}
                            </Heading>
                            <Text type={TextType.body}>
                                {i18n.t('AnonymousInviteHome.ErrorBody')}
                            </Text>
                            <Button
                                text={i18n.t('Back')}
                                onClick={() =>
                                    window.location.replace(
                                        'https://www.defa.com/support/defa-power/'
                                    )
                                }
                            />
                        </Group>
                    )}

                    {done && (
                        <>
                            <IconContainer>
                                <Icon icon="checkCircle" color="#66BB6A" size={48} />
                            </IconContainer>
                            <Group>
                                <Heading level={Level.h1}>
                                    {i18n.t('AnonymousInviteHome.SuccessHeader')}
                                </Heading>
                                <Text>{i18n.t('AnonymousInviteHome.SuccessBody')}</Text>
                            </Group>
                            <IconContainer>
                                {isIOS() && (
                                    <IOSDownload
                                        href="https://apps.apple.com/se/app/defa/id542334548"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />
                                )}
                                {isAndroid() && (
                                    <AndroidDownload
                                        href="https://play.google.com/store/apps/details?id=com.est.defa&pcampaignid=web_share"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />
                                )}
                            </IconContainer>
                        </>
                    )}

                    {showForm && (
                        <>
                            <Group>
                                <Heading level={Level.h1}>
                                    {i18n.t('AnonymousInviteHome.Header')}
                                </Heading>
                                <Text type={TextType.body}>
                                    {i18n.t('AnonymousInviteHome.Body')}
                                </Text>
                            </Group>
                            <Group>
                                <Grid gap={theme.spacingRaw(2)} columns="1fr 2fr" rows="auto">
                                    <Dropdown<string>
                                        name="country"
                                        label={i18n.t('AnonymousInviteHome.CountryLabel')}
                                        placeholder={i18n.t(
                                            'OrganizationSettings.CountryPlaceholder'
                                        )}
                                        onChange={(selectedOption) => {
                                            const normalized = selectedOption.toUpperCase();
                                            setSelectedCountryCode(normalized);
                                        }}
                                        value={selectedCountryCode}
                                        disabled={loadingSave}
                                        items={countriesList.map((c) => c.countryCode)}
                                        keyExtractor={(option) => option}
                                        labelExtractor={(option) => {
                                            const matched = countriesList.find(
                                                (c) => c.countryCode === option
                                            );
                                            return matched
                                                ? `${matched.flag} ${matched.callingCode} ${matched.countryCode}`
                                                : option;
                                        }}
                                    />
                                    <TextInput
                                        label={i18n.t('AnonymousInviteHome.PhoneNumberLabel')}
                                        name="phone"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        type="phone"
                                        autocomplete="username"
                                        disabled={loadingSave}
                                        informationType={InformationType.ERROR}
                                        message={
                                            isPhoneNumberOk
                                                ? undefined
                                                : i18n.t('PhoneNumberInvalid')
                                        }
                                    />
                                </Grid>
                                <Group>
                                    <Button
                                        text={i18n.t('AnonymousInviteHome.SubmitButton')}
                                        disabled={!isPhoneNumberOk || loadingSave}
                                        type="submit"
                                        name="login-button"
                                        loading={loadingSave}
                                    />
                                </Group>
                            </Group>
                        </>
                    )}
                </Group>
            </Screen>
        </ThemeProvider>
    );
};
