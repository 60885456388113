import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const validatePhoneNumber = (phoneNumberString: string): boolean => {
    const phoneNumber = parsePhoneNumberFromString(phoneNumberString);
    return phoneNumber ? phoneNumber.isValid() : false;
};

export const validateCombinedPhoneNumber = (countryCode: string, phoneNumber: string): boolean => {
    const normalizedCountryCode = countryCode.startsWith('+') ? countryCode : `+${countryCode}`;
    const fullNumber = normalizedCountryCode + phoneNumber;
    return validatePhoneNumber(fullNumber);
};

/**
 * Since we only need a raw phone number to pass to the backend,
 * we simply remove a leading zero from the local part (if present)
 * and prepend the dialing code.
 */
export const formatPhoneNumber = (dialingCode: string, local: string): string => {
    const trimmedLocal = local.startsWith('0') ? local.substring(1) : local;
    return dialingCode.startsWith('+')
        ? dialingCode + trimmedLocal
        : `+${dialingCode}${trimmedLocal}`;
};

export const updatePhoneNumberWithCountry = (
    currentPhoneNumber: string,
    callingCode: string
): string => {
    const normalizedCallingCode = callingCode.startsWith('+') ? callingCode : `+${callingCode}`;
    if (currentPhoneNumber.startsWith(normalizedCallingCode)) {
        return currentPhoneNumber;
    }
    const trimmedLocal = currentPhoneNumber.startsWith('0')
        ? currentPhoneNumber.substring(1)
        : currentPhoneNumber;
    return normalizedCallingCode + trimmedLocal;
};
