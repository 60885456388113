import { styled } from '@defa/defa-component-library';

export const IconContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

export const IOSDownload = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background-image: url('/images/download-ios.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    text-decoration: none;
    cursor: pointer;
`;

export const AndroidDownload = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background-image: url('/images/download-android.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    text-decoration: none;
    cursor: pointer;
`;
