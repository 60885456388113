import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import emojiFlags from 'emoji-flags';

export const getCountryCodeValueList = (countryCodes: CountryCode[]) => {
    const countryCodeValues = countryCodes.map((countryCode) => ({
        callingCode: `+${getCountryCallingCode(countryCode)}`,
        countryCode,
        country: emojiFlags.countryCode(countryCode)?.name,
        flag: emojiFlags.countryCode(countryCode)?.emoji,
    }));

    const sortedCountryCodeValues = [...countryCodeValues]?.sort((a, b) =>
        a.country > b.country ? 1 : -1
    );
    return sortedCountryCodeValues;
};

// The type returned by getCountryCodeValueList is an array,
// so this is one element from that array.
export type CountryType = ReturnType<typeof getCountryCodeValueList>[number];
