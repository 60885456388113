import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
    Button,
    Dropdown,
    Group,
    Heading,
    InformationType,
    Level,
    TextInput,
    useTheme,
    Text,
    TextType,
    Composite,
    Icon,
} from '@defa/defa-component-library';
import type { CountryCode } from 'libphonenumber-js';
// eslint-disable-next-line import/extensions
import { CountryType, getCountryCodeValueList } from 'src/utils/country-list';
import { useQueryParam } from '../../utils/hooks';
import { Root, Hero, Message } from './anonymous-invite-facility.styles';
import { BrandPanel } from '../../fractions/brand-panel';
import i18n from '../../i18n';
import { validateEmail } from '../../utils/email';
import { AnonymousSetupResponseStatus } from '../../models';
import { MarkDown } from '../../fractions/mark-down';
import {
    useAcceptAnonymousInvitationFacilityMutation,
    useValidateAnonymousInvitationFacilityQuery,
} from './anonymous-invite-facility.queries.generated';

export const AnonymousInviteFacility: React.FunctionComponent = () => {
    const theme = useTheme();
    const query = useQueryParam();
    const token = query.get('token');

    const [email, setEmail] = useState<string>('');
    const [done, setDone] = useState<boolean>(false);
    const [error, setError] = useState<AnonymousSetupResponseStatus | undefined>();
    const [selectedCountryCode, setSelectedCountryCode] = useState<string | undefined>();
    const isEmailOk = validateEmail(email);
    const isOk = isEmailOk && selectedCountryCode;

    const {
        data,
        loading,
        error: validateError,
        refetch,
    } = useValidateAnonymousInvitationFacilityQuery({
        fetchPolicy: 'network-only',
        variables: { token: token ?? '' },
    });

    const { anonymousInvite, countries = [] } = data ?? {};
    const { numOfConnectors, projectName, status } = anonymousInvite || {};

    const countryCodes: CountryCode[] = countries
        .map((c) => c?.id)
        .filter((id): id is CountryCode => !!id);
    const countriesList: CountryType[] = getCountryCodeValueList(countryCodes);
    const country = countriesList.find((c) => c.countryCode === selectedCountryCode);

    const [acceptInvite, { loading: loadingSave }] = useAcceptAnonymousInvitationFacilityMutation();

    const submit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();
            const { data: accept } = await acceptInvite({
                variables: {
                    email,
                    countryCode: country?.countryCode ?? '',
                    token: token ?? '',
                },
            });
            if (
                accept?.acceptAnonymousInviteFacility?.status ===
                AnonymousSetupResponseStatus.SUCCESS
            ) {
                setDone(true);
            } else {
                setError(AnonymousSetupResponseStatus.INVALID);
            }
        },
        [acceptInvite, country?.countryCode, email, token]
    );

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (validateError) {
            setError(AnonymousSetupResponseStatus.INVALID);
        }
    }, [validateError]);

    const hasError =
        status === AnonymousSetupResponseStatus.INVALID ||
        status === AnonymousSetupResponseStatus.NO_TOKEN ||
        error;

    const showForm = !done && !hasError && status === AnonymousSetupResponseStatus.SUCCESS;

    return (
        <>
            <Root
                first={
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                    </Group>
                }
                secondSideLoading={loading}
                second={
                    <Group minWidth="480px" maxWidth="480px" form onSubmit={submit}>
                        <Hero>
                            <Heading color={theme.foreground.light} level={Level.h1}>
                                {i18n.t('AnonymousInvite.Header')}
                            </Heading>
                        </Hero>
                        {hasError && (
                            <>
                                <Text type={TextType.body}>{i18n.t('AnonymousInvite.Error')}</Text>
                                <Button
                                    text={i18n.t('Back')}
                                    onClick={() => window.location.replace('/')}
                                />
                            </>
                        )}
                        {done && (
                            <Group>
                                <MarkDown>{i18n.t('AnonymousInvite.Success')}</MarkDown>
                                <Button
                                    text={i18n.t('Back')}
                                    onClick={() => window.location.replace('/')}
                                />
                            </Group>
                        )}
                        {showForm && (
                            <>
                                <Group>
                                    <Text type={TextType.body}>
                                        {i18n.t('AnonymousInvite.Intro')}
                                    </Text>
                                    <Composite fillParent>
                                        <TextInput
                                            label={i18n.t('AnonymousInvite.Chargers')}
                                            name="connectors"
                                            value={numOfConnectors?.toString()}
                                            disabled
                                        />
                                        <TextInput
                                            label={i18n.t('AnonymousInvite.Description')}
                                            name="description"
                                            value={projectName ?? ''}
                                            disabled
                                        />
                                    </Composite>
                                </Group>
                                <Group>
                                    <Text type={TextType.body}>
                                        {i18n.t('AnonymousInvite.Body')}
                                    </Text>
                                    <TextInput
                                        label={i18n.t('AnonymousInvite.EmailLabel')}
                                        name="email"
                                        value={email}
                                        onChange={setEmail}
                                        type="email"
                                        autocomplete="username"
                                        disabled={loadingSave}
                                        informationType={InformationType.ERROR}
                                        message={isEmailOk ? undefined : i18n.t('EmailInvalid')}
                                    />
                                    <Dropdown<string>
                                        name="country"
                                        label={i18n.t('AnonymousInviteHome.CountryLabel')}
                                        placeholder={i18n.t(
                                            'OrganizationSettings.CountryPlaceholder'
                                        )}
                                        onChange={(selectedOption) => {
                                            const normalized = selectedOption.toUpperCase();
                                            setSelectedCountryCode(normalized);
                                        }}
                                        value={selectedCountryCode}
                                        disabled={loadingSave}
                                        items={countriesList.map((c) => c.countryCode)}
                                        keyExtractor={(option) => option}
                                        labelExtractor={(option) => {
                                            const matched = countriesList.find(
                                                (c) => c.countryCode === option
                                            );
                                            return matched
                                                ? `${matched.flag}${matched.country}`
                                                : option;
                                        }}
                                    />
                                    <Message>
                                        <Composite gap={theme.spacingRaw(2)} align="start">
                                            <Icon
                                                icon="information"
                                                color={theme.background.attention}
                                                size={16}
                                            />
                                            <Text type={TextType.description}>
                                                {i18n.t('AnonymousInvite.Information')}
                                            </Text>
                                        </Composite>
                                    </Message>
                                </Group>
                                <Group>
                                    <Button
                                        text={i18n.t('AnonymousInvite.SubmitButton')}
                                        disabled={!isOk || loadingSave}
                                        type="submit"
                                        name="login-button"
                                        loading={loadingSave}
                                    />
                                </Group>
                            </>
                        )}
                    </Group>
                }
            />
        </>
    );
};
