/* THIS FILE IS GENERATED. DO NOT EDIT */
/* eslint-disable semi, import/order, import/newline-after-import */

import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateAnonymousInvitationFacilityQueryVariables = Types.Exact<{
    token: Types.Scalars['String']['input'];
}>;

export type ValidateAnonymousInvitationFacilityQuery = {
    __typename?: 'Root';
    anonymousInvite: {
        __typename?: 'AnonymousInvite';
        numOfConnectors?: number | null;
        projectName?: string | null;
        status: string;
        addressDetails?: {
            __typename?: 'AddressDetails';
            streetNumber?: string | null;
            address?: string | null;
            zipCode?: string | null;
            city?: string | null;
            country?: string | null;
        } | null;
    };
    countries: Array<{ __typename?: 'Country'; id: string } | null>;
};

export type AcceptAnonymousInvitationFacilityMutationVariables = Types.Exact<{
    token: Types.Scalars['String']['input'];
    email: Types.Scalars['String']['input'];
    countryCode: Types.Scalars['String']['input'];
}>;

export type AcceptAnonymousInvitationFacilityMutation = {
    __typename?: 'Root';
    acceptAnonymousInviteFacility: { __typename?: 'AcceptAnonymousInviteResponse'; status: string };
};

export const ValidateAnonymousInvitationFacilityDocument = gql`
    query ValidateAnonymousInvitationFacility($token: String!) {
        anonymousInvite(token: $token) {
            numOfConnectors
            projectName
            addressDetails {
                streetNumber
                address
                zipCode
                city
                country
            }
            status
        }
        countries {
            id
        }
    }
`;

/**
 * __useValidateAnonymousInvitationFacilityQuery__
 *
 * To run a query within a React component, call `useValidateAnonymousInvitationFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAnonymousInvitationFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateAnonymousInvitationFacilityQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateAnonymousInvitationFacilityQuery(
    baseOptions: Apollo.QueryHookOptions<
        ValidateAnonymousInvitationFacilityQuery,
        ValidateAnonymousInvitationFacilityQueryVariables
    > &
        (
            | { variables: ValidateAnonymousInvitationFacilityQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ValidateAnonymousInvitationFacilityQuery,
        ValidateAnonymousInvitationFacilityQueryVariables
    >(ValidateAnonymousInvitationFacilityDocument, options);
}
export function useValidateAnonymousInvitationFacilityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ValidateAnonymousInvitationFacilityQuery,
        ValidateAnonymousInvitationFacilityQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ValidateAnonymousInvitationFacilityQuery,
        ValidateAnonymousInvitationFacilityQueryVariables
    >(ValidateAnonymousInvitationFacilityDocument, options);
}
// export function useValidateAnonymousInvitationFacilitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ValidateAnonymousInvitationFacilityQuery, ValidateAnonymousInvitationFacilityQueryVariables>) {
//           const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
//           return Apollo.useSuspenseQuery<ValidateAnonymousInvitationFacilityQuery, ValidateAnonymousInvitationFacilityQueryVariables>(ValidateAnonymousInvitationFacilityDocument, options);
//         }
export type ValidateAnonymousInvitationFacilityQueryHookResult = ReturnType<
    typeof useValidateAnonymousInvitationFacilityQuery
>;
export type ValidateAnonymousInvitationFacilityLazyQueryHookResult = ReturnType<
    typeof useValidateAnonymousInvitationFacilityLazyQuery
>;
// export type ValidateAnonymousInvitationFacilitySuspenseQueryHookResult = ReturnType<
//     typeof useValidateAnonymousInvitationFacilitySuspenseQuery
// >;
export type ValidateAnonymousInvitationFacilityQueryResult = Apollo.QueryResult<
    ValidateAnonymousInvitationFacilityQuery,
    ValidateAnonymousInvitationFacilityQueryVariables
>;
export const AcceptAnonymousInvitationFacilityDocument = gql`
    mutation AcceptAnonymousInvitationFacility(
        $token: String!
        $email: String!
        $countryCode: String!
    ) {
        acceptAnonymousInviteFacility(
            input: { token: $token, email: $email, countryCode: $countryCode }
        ) {
            status
        }
    }
`;
export type AcceptAnonymousInvitationFacilityMutationFn = Apollo.MutationFunction<
    AcceptAnonymousInvitationFacilityMutation,
    AcceptAnonymousInvitationFacilityMutationVariables
>;

/**
 * __useAcceptAnonymousInvitationFacilityMutation__
 *
 * To run a mutation, you first call `useAcceptAnonymousInvitationFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAnonymousInvitationFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAnonymousInvitationFacilityMutation, { data, loading, error }] = useAcceptAnonymousInvitationFacilityMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useAcceptAnonymousInvitationFacilityMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AcceptAnonymousInvitationFacilityMutation,
        AcceptAnonymousInvitationFacilityMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AcceptAnonymousInvitationFacilityMutation,
        AcceptAnonymousInvitationFacilityMutationVariables
    >(AcceptAnonymousInvitationFacilityDocument, options);
}
export type AcceptAnonymousInvitationFacilityMutationHookResult = ReturnType<
    typeof useAcceptAnonymousInvitationFacilityMutation
>;
export type AcceptAnonymousInvitationFacilityMutationResult = Apollo.MutationResult<
    AcceptAnonymousInvitationFacilityMutation
>;
export type AcceptAnonymousInvitationFacilityMutationOptions = Apollo.BaseMutationOptions<
    AcceptAnonymousInvitationFacilityMutation,
    AcceptAnonymousInvitationFacilityMutationVariables
>;
