import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    Button,
    Composite,
    Dropdown,
    Group,
    Heading,
    InformationType,
    Level,
    ProgressIndicator,
    ProgressType,
    Text,
    TextInput,
    TextInputMode,
    Variant,
} from '@defa/defa-component-library';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { PhoneNumberContainer } from './account.styles';
import i18n from '../../i18n';
import { ADMIN, UPDATE_ADMIN, UPDATE_PASSWORD } from './account.queries';
import { ChangePasswordModal } from '../../fractions/change-password-modal';
import { NotificationContext } from '../../utils/notification';
import { PhoneCountryCode } from '../../models/phone';
import { PHONE_COUNTRY_CODES } from '../../constants';
import { LoadingWrapper } from './settings.styles';
import { validatePhoneNumber } from '../../utils/phone-number';

export const AccountSettings: React.FunctionComponent = () => {
    const { add: addNotification } = useContext(NotificationContext);
    const { refetch: refetchAdmin, data: adminData, loading, error } = useQuery(ADMIN);
    const { admin } = adminData || { admin: {} };
    const defaultAdmin = React.useMemo(() => ({}), []);

    const {
        email: initialEmail,
        firstName: initialFirstName,
        lastName: initialLastName,
        phoneNumber: initialPhone,
    } = admin || defaultAdmin;

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneCountryCode, setPhoneCountryCode] = useState<PhoneCountryCode>();
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const [editPassword, setEditPassword] = useState<boolean>(false);

    const validPhoneNumber = phoneNumber === '' || validatePhoneNumber(phoneNumber);
    const phoneNumberMessage = validPhoneNumber ? undefined : i18n.t('PhoneNumberInvalid');

    const valid = firstName && lastName && phoneNumber && phoneCountryCode && validPhoneNumber;

    const [updateAdmin, { loading: loadingUpdateAdmin }] = useMutation(UPDATE_ADMIN);
    const [changePassword, { loading: loadingChangePassword }] = useMutation(UPDATE_PASSWORD);

    useEffect(() => {
        refetchAdmin();
    }, [refetchAdmin]);

    useEffect(() => {
        setFirstName(initialFirstName);
        setLastName(initialLastName);
        setEmail(initialEmail);
    }, [initialEmail, initialFirstName, initialLastName]);

    useEffect(() => {
        if (initialPhone?.length > 0) {
            const parsedPhoneNumber = parsePhoneNumberFromString(`+${initialPhone}`);
            if (parsedPhoneNumber) {
                const countryCode = PHONE_COUNTRY_CODES.find(
                    (c) => c.value === parsedPhoneNumber.countryCallingCode
                );
                if (countryCode) {
                    setPhoneCountryCode(countryCode);
                    setPhoneNumber(parsedPhoneNumber.nationalNumber);
                } else {
                    setPhoneNumber(initialPhone);
                }
            }
        }
    }, [initialPhone]);

    const onFirstNameChange = React.useMemo(
        () => (text: string): void => {
            setFirstName(text);
        },
        [setFirstName]
    );

    const onLastNameChange = React.useMemo(
        () => (text: string): void => {
            setLastName(text);
        },
        [setLastName]
    );

    const onPhoneCountryCodeChange = (countryCode: PhoneCountryCode) => {
        setPhoneCountryCode(countryCode);
    };

    const onPhoneNumberChange = React.useMemo(
        () => (text: string): void => {
            setPhoneNumber(text);
        },
        [setPhoneNumber]
    );

    const onSubmitPasswordChange = async (currentPassword: string, newPassword: string) => {
        const {
            data: { changePassword: passwordResponse },
        } = await changePassword({
            variables: {
                currentPassword,
                newPassword,
            },
        });
        const { status } = passwordResponse;
        if (status === 200) {
            addNotification({
                message: i18n.t('AccountSettings.PasswordChangeSuccess'),
            });
        } else {
            addNotification({
                message: i18n.t('AccountSettings.PasswordChangeError'),
            });
        }
        setEditPassword(false);
    };

    const submit = React.useMemo(
        () => (): void => {
            if (valid) {
                updateAdmin({
                    variables: {
                        firstName,
                        lastName,
                        phoneNumber: `${phoneCountryCode?.value}${phoneNumber}`,
                        email,
                    },
                })
                    .then(() => {
                        addNotification({
                            message: i18n.t('AccountSettings.Success'),
                        });
                    })
                    .catch(() => {
                        addNotification({
                            message: i18n.t('AccountSettings.Error'),
                        });
                    });
            }
        },
        [
            valid,
            updateAdmin,
            firstName,
            lastName,
            phoneNumber,
            phoneCountryCode,
            email,
            addNotification,
        ]
    );

    return (
        <>
            <Group>
                {error && <p>ERROR...</p>}
                <Group>
                    <Heading level={Level.h4}>{i18n.t('AccountSettings.Header')}</Heading>
                    {loading ? (
                        <LoadingWrapper>
                            <ProgressIndicator type={ProgressType.DONUT_LOADING} progress={50} />
                        </LoadingWrapper>
                    ) : (
                        <>
                            <TextInput
                                label={i18n.t('AccountSettings.FirstNameLabel')}
                                name="first-name"
                                value={firstName}
                                onChange={onFirstNameChange}
                            />
                            <TextInput
                                label={i18n.t('AccountSettings.LastNameLabel')}
                                name="last-name"
                                value={lastName}
                                onChange={onLastNameChange}
                            />
                            <TextInput
                                label={i18n.t('AccountSettings.EmailLabel')}
                                name="email"
                                mode={TextInputMode.EMAIL}
                                value={email}
                                message={i18n.t('AccountSettings.EmailDescription')}
                                informationType={InformationType.INFO}
                                disabled
                            />
                            <PhoneNumberContainer>
                                <Dropdown<PhoneCountryCode>
                                    name="phone-country-codes"
                                    label={i18n.t('AccountSettings.PhoneNumberCountryLabel')}
                                    placeholder={i18n.t('AccountSettings.PhoneNumberCountryLabel')}
                                    onChange={onPhoneCountryCodeChange}
                                    value={phoneCountryCode}
                                    items={PHONE_COUNTRY_CODES}
                                    keyExtractor={(item: PhoneCountryCode) => item.label}
                                    labelExtractor={(item: PhoneCountryCode) => item.label}
                                />
                                <TextInput
                                    label={i18n.t('AccountSettings.PhoneNumberLabel')}
                                    name="phone-number"
                                    value={phoneNumber}
                                    message={phoneNumberMessage}
                                    onChange={onPhoneNumberChange}
                                />
                            </PhoneNumberContainer>
                        </>
                    )}
                </Group>
                <Group>
                    <Group>
                        <Heading level={Level.h4}>
                            {i18n.t('AccountSettings.PasswordLabel')}
                        </Heading>
                        <Composite>
                            <Text>•••••••••</Text>
                            <Button
                                variant={Variant.SECONDARY}
                                fillParent={false}
                                text={i18n.t('Change')}
                                onClick={() => setEditPassword(!editPassword)}
                            />
                        </Composite>
                    </Group>
                </Group>
                <Button
                    name="submit-button"
                    text={i18n.t('Save')}
                    variant={Variant.PRIMARY}
                    disabled={!valid || loading}
                    loading={loadingUpdateAdmin}
                    onClick={submit}
                />
            </Group>
            <ChangePasswordModal
                onClose={() => setEditPassword(false)}
                onSave={onSubmitPasswordChange}
                showModal={editPassword}
                loading={loadingChangePassword}
            />
        </>
    );
};
