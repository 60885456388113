import React, { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
    Button,
    Group,
    Heading,
    Level,
    TextInput,
    TextInputMode,
    CheckBox,
    Link,
    Composite,
    Text,
    TextType,
    Switch,
    InformationType,
    Dropdown,
} from '@defa/defa-component-library';
import { useAuthState } from '../../auth';
import { PhoneNumberContainer, Root } from './create-account.styles';

import { BrandPanel } from '../../fractions/brand-panel';

import i18n from '../../i18n';
import { useQueryParam } from '../../utils/hooks';
import { testPasswordPolicy } from '../../utils/password-policy';
import { validateEmail } from '../../utils/email';
import { PHONE_COUNTRY_CODES } from '../../constants';
import { PhoneCountryCode } from '../../models/phone';
import { validatePhoneNumber } from '../../utils/phone-number';

type CreateAccountProps = {} & RouteComponentProps<{}, {}, { from: string }>;

export const CreateAccount: React.FunctionComponent<CreateAccountProps> = ({
    location,
}: CreateAccountProps) => {
    const query = useQueryParam();
    const token = query.get('token');
    const adminId = query.get('adminId');

    const { isAuthenticated, createAccount } = useAuthState();
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [phoneCountryCode, setPhoneCountryCode] = useState<PhoneCountryCode>();
    const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);

    const { from } = location.state || { from: { pathname: '/' } };

    const validEmail = email === '' || validateEmail(email);
    const emailMessage = validEmail ? undefined : i18n.t('EmailInvalid');
    const validPhoneNumber = phoneNumber === '' || validatePhoneNumber(phoneNumber);
    const phoneNumberMessage = validPhoneNumber ? undefined : i18n.t('PhoneNumberInvalid');
    const emailInformationType = validEmail ? undefined : InformationType.ERROR;

    const valid =
        firstName &&
        lastName &&
        email &&
        phoneNumber &&
        phoneCountryCode?.value &&
        password &&
        privacyAccepted &&
        password === confirmPassword &&
        testPasswordPolicy(password) &&
        validEmail &&
        validPhoneNumber;

    const submit = React.useMemo(
        () => (): void => {
            if (valid) {
                createAccount({
                    adminId,
                    firstName,
                    lastName,
                    email,
                    phoneNumber: `${phoneCountryCode?.value}${phoneNumber}`,
                    inviteToken: token,
                    password,
                    acceptedPrivacyConditions: privacyAccepted,
                });
            }
        },
        [
            valid,
            createAccount,
            adminId,
            firstName,
            lastName,
            email,
            phoneNumber,
            phoneCountryCode,
            token,
            password,
            privacyAccepted,
        ]
    );

    const onFirstNameChange = React.useMemo(
        () => (text: string): void => {
            setFirstName(text);
        },
        [setFirstName]
    );

    const onLastNameChange = React.useMemo(
        () => (text: string): void => {
            setLastName(text);
        },
        [setLastName]
    );

    const onEmailChange = React.useMemo(
        () => (text: string): void => {
            setEmail(text);
        },
        [setEmail]
    );

    const onPhoneNumberChange = React.useMemo(
        () => (text: string): void => {
            setPhoneNumber(text);
        },
        [setPhoneNumber]
    );

    const onPasswordChange = React.useMemo(
        () => (text: string): void => {
            setPassword(text);
        },
        [setPassword]
    );
    const onConfirmPasswordChange = React.useMemo(
        () => (text: string): void => {
            setConfirmPassword(text);
        },
        [setConfirmPassword]
    );

    const onShowPasswordChange = React.useMemo(
        () => (checked: boolean): void => {
            setShowPassword(checked);
        },
        [setShowPassword]
    );

    const onPhoneCountryCodeChange = (countryCode: PhoneCountryCode) => {
        setPhoneCountryCode(countryCode);
    };

    const onPrivacyAcceptedChange = React.useMemo(
        () => (checked: boolean): void => {
            setPrivacyAccepted(checked);
        },
        [setPrivacyAccepted]
    );

    const showPrivacyPolicy = () => {
        window.open('https://www.cloudcharge.tech/privacy/', '_blank');
    };

    if (isAuthenticated) {
        return <Redirect to={from} />;
    }

    return (
        <Root
            first={
                <Group>
                    <BrandPanel
                        heading={i18n.t('SetupAccount.FlowHeader')}
                        subHeading={i18n.t('BrandPanel.SubHeader')}
                    />
                </Group>
            }
            second={
                <Group minWidth="480px" form>
                    <Group>
                        <Heading level={Level.h1}>{i18n.t('CreateAccount.Header')}</Heading>
                    </Group>

                    <Group>
                        <TextInput
                            label={i18n.t('CreateAccount.FirstNameLabel')}
                            name="first-name"
                            value={firstName}
                            onChange={onFirstNameChange}
                            autocomplete="given-name"
                        />
                        <TextInput
                            label={i18n.t('CreateAccount.LastNameLabel')}
                            name="last-name"
                            value={lastName}
                            onChange={onLastNameChange}
                            autocomplete="family-name"
                        />
                        <PhoneNumberContainer>
                            <Dropdown<PhoneCountryCode>
                                name="phone-country-codes"
                                label={i18n.t('CreateAccount.PhoneNumberCountry')}
                                placeholder={i18n.t('CreateAccount.PhoneNumberCountry')}
                                onChange={onPhoneCountryCodeChange}
                                value={phoneCountryCode}
                                items={PHONE_COUNTRY_CODES}
                                keyExtractor={(item) => item.value}
                                labelExtractor={(item) => item.label}
                            />
                            <TextInput
                                label={i18n.t('CreateAccount.PhoneNumberLabel')}
                                name="phone-number"
                                value={phoneNumber}
                                onChange={onPhoneNumberChange}
                                message={phoneNumberMessage}
                                autocomplete="tel-national"
                            />
                        </PhoneNumberContainer>

                        <TextInput
                            label={i18n.t('CreateAccount.EmailLabel')}
                            id="email"
                            name="email"
                            mode={TextInputMode.EMAIL}
                            value={email}
                            onChange={onEmailChange}
                            autocomplete="email"
                            message={emailMessage}
                            informationType={emailInformationType}
                        />
                        <Group>
                            <Composite>
                                <TextInput
                                    label={i18n.t('CreateAccount.PasswordLabel')}
                                    name="password"
                                    mode={
                                        showPassword
                                            ? TextInputMode.PLAIN_TEXT
                                            : TextInputMode.PASSWORD
                                    }
                                    message={
                                        password.length > 0 &&
                                        !testPasswordPolicy(password) &&
                                        i18n.t('CreateAccount.PasswordPolicyErrorMessage')
                                    }
                                    autocomplete="new-password"
                                    value={password}
                                    onChange={onPasswordChange}
                                />
                                <TextInput
                                    label={i18n.t('CreateAccount.ConfirmPasswordLabel')}
                                    name="confirm-password"
                                    mode={
                                        showPassword
                                            ? TextInputMode.PLAIN_TEXT
                                            : TextInputMode.PASSWORD
                                    }
                                    value={confirmPassword}
                                    message={
                                        confirmPassword.length > 0 &&
                                        password !== confirmPassword &&
                                        i18n.t('ChangePassword.MessageMatch')
                                    }
                                    onChange={onConfirmPasswordChange}
                                />
                            </Composite>
                            <Text type={TextType.description}>
                                {i18n.t('CreateAccount.PasswordDescription')}
                            </Text>
                            <Switch
                                justify="start"
                                reverse
                                label={i18n.t('CreateAccount.ShowPasswordSwitch')}
                                name="show-password-switch"
                                checked={showPassword}
                                onChange={onShowPasswordChange}
                            />
                        </Group>
                    </Group>
                    <Group>
                        <CheckBox
                            name="privacy"
                            label={i18n.t('CreateAccount.PrivacyConditionsLabel')}
                            checked={privacyAccepted}
                            onChange={onPrivacyAcceptedChange}
                            extra={
                                <Link href="#" onClick={showPrivacyPolicy}>
                                    {i18n.t('CreateAccount.PrivacyConditionsLink')}
                                </Link>
                            }
                        />
                    </Group>
                    <Group>
                        <Button
                            text={i18n.t('CreateAccount.SubmitButton')}
                            disabled={!valid}
                            onClick={submit}
                            name="submit-button"
                        />
                    </Group>
                </Group>
            }
        />
    );
};
