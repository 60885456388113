/* THIS FILE IS GENERATED. DO NOT EDIT */
/* eslint-disable semi, import/order, import/newline-after-import */

import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateAnonymousInvitationHomeQueryVariables = Types.Exact<{
    token: Types.Scalars['String']['input'];
}>;

export type ValidateAnonymousInvitationHomeQuery = {
    __typename?: 'Root';
    anonymousInvite: {
        __typename?: 'AnonymousInvite';
        numOfConnectors?: number | null;
        projectName?: string | null;
        status: string;
    };
    countries: Array<{ __typename?: 'Country'; id: string } | null>;
};

export type AcceptAnonymousInvitationHomeMutationVariables = Types.Exact<{
    token: Types.Scalars['String']['input'];
    phoneNumber: Types.Scalars['String']['input'];
    countryCode: Types.Scalars['String']['input'];
}>;

export type AcceptAnonymousInvitationHomeMutation = {
    __typename?: 'Root';
    acceptAnonymousInviteHome: { __typename?: 'AcceptAnonymousInviteResponse'; status: string };
};

export const ValidateAnonymousInvitationHomeDocument = gql`
    query ValidateAnonymousInvitationHome($token: String!) {
        anonymousInvite(token: $token) {
            numOfConnectors
            projectName
            status
        }
        countries {
            id
        }
    }
`;

/**
 * __useValidateAnonymousInvitationHomeQuery__
 *
 * To run a query within a React component, call `useValidateAnonymousInvitationHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAnonymousInvitationHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateAnonymousInvitationHomeQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateAnonymousInvitationHomeQuery(
    baseOptions: Apollo.QueryHookOptions<
        ValidateAnonymousInvitationHomeQuery,
        ValidateAnonymousInvitationHomeQueryVariables
    > &
        (
            | { variables: ValidateAnonymousInvitationHomeQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ValidateAnonymousInvitationHomeQuery,
        ValidateAnonymousInvitationHomeQueryVariables
    >(ValidateAnonymousInvitationHomeDocument, options);
}
export function useValidateAnonymousInvitationHomeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ValidateAnonymousInvitationHomeQuery,
        ValidateAnonymousInvitationHomeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ValidateAnonymousInvitationHomeQuery,
        ValidateAnonymousInvitationHomeQueryVariables
    >(ValidateAnonymousInvitationHomeDocument, options);
}
// export function useValidateAnonymousInvitationHomeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ValidateAnonymousInvitationHomeQuery, ValidateAnonymousInvitationHomeQueryVariables>) {
//           const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
//           return Apollo.useSuspenseQuery<ValidateAnonymousInvitationHomeQuery, ValidateAnonymousInvitationHomeQueryVariables>(ValidateAnonymousInvitationHomeDocument, options);
//         }
export type ValidateAnonymousInvitationHomeQueryHookResult = ReturnType<
    typeof useValidateAnonymousInvitationHomeQuery
>;
export type ValidateAnonymousInvitationHomeLazyQueryHookResult = ReturnType<
    typeof useValidateAnonymousInvitationHomeLazyQuery
>;
// export type ValidateAnonymousInvitationHomeSuspenseQueryHookResult = ReturnType<typeof useValidateAnonymousInvitationHomeSuspenseQuery>;
export type ValidateAnonymousInvitationHomeQueryResult = Apollo.QueryResult<
    ValidateAnonymousInvitationHomeQuery,
    ValidateAnonymousInvitationHomeQueryVariables
>;
export const AcceptAnonymousInvitationHomeDocument = gql`
    mutation AcceptAnonymousInvitationHome(
        $token: String!
        $phoneNumber: String!
        $countryCode: String!
    ) {
        acceptAnonymousInviteHome(
            input: { token: $token, phoneNumber: $phoneNumber, countryCode: $countryCode }
        ) {
            status
        }
    }
`;
export type AcceptAnonymousInvitationHomeMutationFn = Apollo.MutationFunction<
    AcceptAnonymousInvitationHomeMutation,
    AcceptAnonymousInvitationHomeMutationVariables
>;

/**
 * __useAcceptAnonymousInvitationHomeMutation__
 *
 * To run a mutation, you first call `useAcceptAnonymousInvitationHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAnonymousInvitationHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAnonymousInvitationHomeMutation, { data, loading, error }] = useAcceptAnonymousInvitationHomeMutation({
 *   variables: {
 *      token: // value for 'token'
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useAcceptAnonymousInvitationHomeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AcceptAnonymousInvitationHomeMutation,
        AcceptAnonymousInvitationHomeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AcceptAnonymousInvitationHomeMutation,
        AcceptAnonymousInvitationHomeMutationVariables
    >(AcceptAnonymousInvitationHomeDocument, options);
}
export type AcceptAnonymousInvitationHomeMutationHookResult = ReturnType<
    typeof useAcceptAnonymousInvitationHomeMutation
>;
export type AcceptAnonymousInvitationHomeMutationResult = Apollo.MutationResult<
    AcceptAnonymousInvitationHomeMutation
>;
export type AcceptAnonymousInvitationHomeMutationOptions = Apollo.BaseMutationOptions<
    AcceptAnonymousInvitationHomeMutation,
    AcceptAnonymousInvitationHomeMutationVariables
>;
