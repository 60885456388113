import { useMutation } from '@apollo/client';
import {
    Button,
    Group,
    Heading,
    InformationType,
    Level,
    Modal,
    Table,
    TextInput,
} from '@defa/defa-component-library';
import React, { useContext, useEffect, useState } from 'react';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useParams } from 'react-router-dom';
import { ChargerAccessContextWindow } from '../../../fractions/charger-access-context-window';
import i18n from '../../../i18n';
import { ConnectorAccess, GroupAccess, InviteUsersResponseData, User } from '../../../models/user';
import { AccessDropdown } from '../../../fractions/access-dropdown';

import { Root } from './add-users.styles';
import { UPDATE_CHARGE_SYSTEM } from './add-users.queries';
import { NotificationContext } from '../../../utils/notification';
import { PhoneCountryCodeWrapper } from '../../setup';
import { PhoneCountryCode } from '../../../models/phone';
import { PHONE_COUNTRY_CODES } from '../../../constants';
import { useCountry } from '../../../utils/hooks';
import { ChargeSystemGroupsAndConnectors } from '../../../models/charge-system';
import { validatePhoneNumber } from '../../../utils/phone-number';

const PhoneNumberFieldInner = ({
    onBlur,
    phoneNumber,
    index,
    errorMessage,
}: {
    onBlur: Function;
    phoneNumber?: string;
    index: number;
    errorMessage?: string;
}) => {
    const [phoneNumberValue, setPhoneNumberValue] = useState(phoneNumber);
    const valid = phoneNumberValue === '' || validatePhoneNumber(phoneNumberValue || '');
    const phoneNumberMessage = valid ? undefined : i18n.t('PhoneNumberInvalid');
    return (
        <TextInput
            name={`phone-${index}`}
            label={i18n.t('AddMoreUsers.PhoneLabel')}
            value={phoneNumberValue}
            onChange={(text) => {
                setPhoneNumberValue(text);
            }}
            message={errorMessage ? i18n.t(`AddMoreUsers.${errorMessage}`) : phoneNumberMessage}
            onBlur={() => onBlur(index, phoneNumberValue)}
            informationType={errorMessage ? InformationType.ERROR : InformationType.INFO}
        />
    );
};

export function PhoneNumberField(updateUserInviteByIndex: Function) {
    return (user: Partial<User>, index: number) => {
        const { phoneNumber, id, errorMessage } = user;
        return (
            <PhoneNumberFieldInner
                onBlur={updateUserInviteByIndex}
                phoneNumber={phoneNumber}
                errorMessage={errorMessage}
                index={index}
                key={`phone-${id}`}
            />
        );
    };
}

function defaultUser(countryCode?: PhoneCountryCode) {
    return {
        phoneNumber: '',
        phoneCountryCode: countryCode,
        groups: [],
        connectors: [],
        id: `${Math.random() * 100000}`,
    };
}

export function AddUsersModal({
    onClose,
    onSubmit,
    dataset,
}: {
    onClose: () => void;
    onSubmit: Function;
    dataset: ChargeSystemGroupsAndConnectors;
}) {
    const { id } = useParams<{ id: string }>();
    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement>();
    const [country] = useCountry();

    const currentCountryCode = React.useMemo(
        () => PHONE_COUNTRY_CODES.find((c) => c.country === country),
        [country]
    );

    const [invites, setInvites] = useState<User[]>([defaultUser(currentCountryCode)]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [ok, setOk] = useState(true);
    const { add: addNotification } = useContext(NotificationContext);

    const [updateChargeSystem, { loading }] = useMutation<InviteUsersResponseData>(
        UPDATE_CHARGE_SYSTEM
    );

    const updateUserByIndex = (index: number, phoneNumber: string) => {
        setInvites((current) => {
            const duplicatePhoneNumber = current.find(
                (invite, i) => invite.phoneNumber === phoneNumber && i !== index
            );

            const updatedArray = [
                ...current.slice(0, index),
                {
                    ...current[index],
                    id: current[index]?.id || `${Math.random() * 10000}`,
                    phoneNumber,
                    errorMessage: duplicatePhoneNumber ? 'DuplicatePhoneNumber' : '',
                },
                ...current.slice(index + 1),
            ];

            if (index === invites.length - 1 && phoneNumber !== '') {
                updatedArray.push(defaultUser(currentCountryCode));
            }
            return updatedArray;
        });
    };

    const setUserPhoneCountryCode = (phoneCountryCode: PhoneCountryCode, index: number) =>
        setInvites((current) => [
            ...current.slice(0, index),
            {
                ...current[index],
                id: current[index]?.id || `${Math.random() * 10000}`,
                phoneCountryCode,
            },
            ...current.slice(index + 1),
        ]);

    const setUserErrorMessage = (errorMessage: string, phoneNumber?: string) => {
        const userIndex = invites.findIndex((user) => user.phoneNumber === phoneNumber);
        const userFound = userIndex > -1;

        if (userFound) {
            setInvites((current) => [
                ...current.slice(0, userIndex),
                { ...current[userIndex], errorMessage },
                ...current.slice(userIndex + 1),
            ]);
        }
    };

    const onDropdownClick = (e: React.MouseEvent, user: User) => {
        const { target } = e;

        setSelectedUser(user);
        setMenuPositioningElement(target as HTMLElement);
    };

    const onChargerAccessSubmit = (groups: GroupAccess[], connectors: ConnectorAccess[]) => {
        setInvites((current: User[]) => {
            const userIndex = current.findIndex((u) => u.id === selectedUser?.id);

            if (!selectedUser || userIndex === -1) {
                return current;
            }

            return [
                ...current.slice(0, userIndex),
                { ...selectedUser, groups, connectors },
                ...current.slice(userIndex + 1),
            ];
        });
        setMenuPositioningElement(undefined);
    };

    const onSubmitPress = async () => {
        const response = await updateChargeSystem({
            variables: {
                id,
                invites: invites
                    .filter(
                        (i) => i.phoneNumber && i.phoneNumber !== '' && i.phoneCountryCode?.value
                    )
                    .map((ua) => ({
                        phoneNumber: `${ua.phoneCountryCode?.value}${ua.phoneNumber}`,
                        connectors: ua.connectors.map((uac) => ({
                            accessLevel: uac.accessLevel,
                            id: uac.data.id,
                        })),
                        groups: ua.groups.map((uag) => ({
                            accessLevel: uag.accessLevel,
                            id: uag.data.id,
                        })),
                    })),
            },
        });

        const inviteUsersResponse = response.data?.chargeSystem.inviteUsers;
        if (inviteUsersResponse?.status === 200) {
            addNotification({
                message: i18n.t('AddMoreUsers.InvitationMessageSuccess'),
            });
            setInvites([defaultUser(currentCountryCode)]);
            onSubmit();
        } else {
            const responseBody = inviteUsersResponse?.body?.map((elem) => ({
                ...elem,
                data: elem.data.map(
                    (item: string) => parsePhoneNumberFromString(`+${item}`)?.nationalNumber
                ),
            }));
            responseBody?.forEach((elem) =>
                elem.data.forEach((item) => setUserErrorMessage(elem.message, item))
            );
        }
    };

    useEffect(() => {
        const errorRows = invites.filter((i) => {
            const hasErrorMessage = i.errorMessage && i.errorMessage !== '';
            const hasPhoneNumber = i.phoneNumber !== '';
            const hasNoConnectorAccess = i.connectors.length === 0;
            const hasNoGroupAccess = i.groups.length === 0;
            const hasNoAccess = hasNoConnectorAccess && hasNoGroupAccess;

            return hasPhoneNumber ? hasErrorMessage || hasNoAccess : false;
        });
        setOk(invites.length > 1 && errorRows.length < 1);
    }, [invites, setOk]);

    return (
        <Modal
            titleContent={<Heading level={Level.h3}>{i18n.t('AddMoreUsers.Header')}</Heading>}
            bodyContent={
                <Root>
                    <Group tight>
                        <Table<User>
                            columnGap="small"
                            items={invites}
                            columnWidths={[undefined, '33%', '33%']}
                            columnNames={[
                                i18n.t('UserAccess.ColumnCountryCode'),
                                i18n.t('UserAccess.ColumnUser'),
                                i18n.t('UserAccess.ColumnAccess'),
                            ]}
                            columns={[
                                PhoneCountryCodeWrapper(setUserPhoneCountryCode),
                                PhoneNumberField(updateUserByIndex),
                                AccessDropdown<User>(onDropdownClick),
                            ]}
                        />
                        <ChargerAccessContextWindow
                            positioningElement={menuPositioningElement}
                            onClosePress={() => setMenuPositioningElement(undefined)}
                            onSubmit={onChargerAccessSubmit}
                            userAccess={selectedUser}
                            dataset={dataset}
                            zIndex={10}
                        />
                    </Group>
                </Root>
            }
            actionContent={
                <Button
                    fillParent
                    disabled={!ok}
                    loading={loading}
                    text={i18n.t('AddMoreUsers.AddButton')}
                    onClick={onSubmitPress}
                />
            }
            onClosePress={onClose}
            zIndex={5}
            width="545px"
            showModal
        />
    );
}
